import { baseApi } from 'redux/api/baseApi';

const AUTH_BASE_URL = 'accounts/';

export const authApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation<LoginResponseData, LoginInputData>({
            query: (data: LoginInputData) => ({
                url: `${AUTH_BASE_URL}login/`,
                method: 'POST',
                body: data
            })
        })
    })
});

export const { useLoginMutation } = authApi;
