import { createTheme } from '@mui/material/styles';
import { luminaColorPallete } from 'styles/mui/components/Color';
import { luminaTypography } from 'styles/mui/components/Typography';
import { luminaButton } from './components/Button';
import { luminaInput } from './components/Input';

// Create a theme instance.
const theme = createTheme({
    typography: {
        fontFamily: ['"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
        ...luminaTypography
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `@font-face {
            font-family: 'Helvetica Neue';
            src: local('Helvetica Neue'), local(Arial), url('https://djhutyrz7bno6.cloudfront.net/fonts/HelveticaNeue/HelveticaNeue-Regular.ttf');
            font-style: normal;
            font-weight: 400;
            font-display: swap;
        }
        @font-face {
            font-family: 'Helvetica Neue';
            src: local('Helvetica Neue'), local(Arial), url('https://djhutyrz7bno6.cloudfront.net/fonts/HelveticaNeue/HelveticaNeue-Medium.ttf');
            font-style: normal;
            font-weight: 500;
            font-display: swap;
        }
        @font-face {
            font-family: 'Helvetica Neue';
            src: local('Helvetica Neue'), local(Arial), url('https://djhutyrz7bno6.cloudfront.net/fonts/HelveticaNeue/HelveticaNeue-Bold.ttf');
            font-style: normal;
            font-weight: black;
            font-display: swap;
        }`
        },
        MuiButton: {
            styleOverrides: {
                contained: luminaButton.contained,
                outlined: luminaButton.outlined,
                text: luminaButton.text,
                containedSecondary: luminaButton.containedFire
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: luminaInput.textInput
            }
        }
    },
    palette: luminaColorPallete
});

export default theme;
