import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authApi } from 'authentication/redux/api/authApi';
import { toast } from 'react-toastify';

type UserSliceState = {
    user: User;
    role: string;
    token: string | null;
};

const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: {} as User,
        token: null,
        role: ''
    } as UserSliceState,
    reducers: {
        removeUser: () => {
            window.localStorage.removeItem('token');
            window.localStorage.removeItem('role');
            return { token: null, user: {} as User, role: '' };
        },
        /**
         * this clearChace reducer action was used to prevent different user login in same device bug
         * the bug was event the user logged in is different, but if we are not clear the cached, redux toolkit
         * still keep the old redux history data, and it's make new user get old data from the old user.
         */
        clearCache: (state: UserSliceState) => {
            return state;
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            authApi.endpoints.login.matchFulfilled,
            (state, { payload }: PayloadAction<LoginResponseData>) => {
                state.user = payload.user;
                state.token = payload.token;
                state.role = payload.role;

                window.localStorage.setItem('token', payload.token);
                window.localStorage.setItem('role', payload.role);

                toast.success(
                    `${state.user.full_name} (${payload.role.toUpperCase()})`,
                    {
                        position: 'top-center',
                        theme: 'colored',
                        hideProgressBar: true
                    }
                );

                return state;
            }
        );
    }
});

export const { removeUser, clearCache } = userSlice.actions;

export default userSlice.reducer;
