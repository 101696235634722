/* eslint-disable @typescript-eslint/ban-ts-comment */
import Head from 'next/head';
import type { AppProps } from 'next/app';
import 'styles/tailwindcss/global.css';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider } from '@emotion/react';

import { PersistGate } from 'redux-persist/integration/react';

import theme from 'styles/mui/theme';
import createEmotionCache from 'styles/mui/createEmotionCache';
import useStore, { wrapper } from 'redux/store';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'moment/locale/id';
import LuminaBackdrop from 'core/components/LuminaBackdrop';

const clientSideEmotionCache = createEmotionCache();
const store = useStore();

const MyApp = ({ Component, pageProps }: AppProps): JSX.Element => {
    return (
        <CacheProvider value={clientSideEmotionCache}>
            <Head>
                <meta name="viewport" content="initial-scale=1, width=device-width" />
                <title>Lumina | Microtask Platform</title>
                <meta name="description" content="Microtask Platform by Lumina" />
            </Head>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {/* @ts-ignore */}
                <PersistGate persistor={store.__persistor} loading={<LuminaBackdrop />}>
                    <Component {...pageProps} />
                </PersistGate>
            </ThemeProvider>
            <ToastContainer />
        </CacheProvider>
    );
};

export default wrapper.withRedux(MyApp);
