import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from './config';

export const baseApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: config.API_BASE_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).authentication.user.token;
            // console.log("eaahm", (getState() as RootState).authentication.user.token);
            const rawToken = window.localStorage.getItem('token');
            if (token || rawToken) {
                headers.set('Authorization', `Token ${token ?? rawToken}`);
            }
            return headers;
        }
    }),
    endpoints: () => ({})
});
