import { CSSObject } from '@emotion/react';
import { luminaColorPallete } from 'styles/mui/components/Color';

export const luminaButton = {
    contained: {
        backgroundColor: `${luminaColorPallete.sky[600]} !important`,
        textTransform: 'none',
        boxShadow: 'none',
        borderRadius: '8px',
        '&:hover': {
            backgroundColor: `${luminaColorPallete.sky[500]} !important`,
            boxShadow: 'none'
        },
        '&:focus': {
            backgroundColor: `${luminaColorPallete.sky[800]} !important`,
            boxShadow: 'none'
        },
        '&:disabled': {
            backgroundColor: `${luminaColorPallete.greyscale[400]} !important`,
            color: `${luminaColorPallete.greyscale[200]} !important`,
            boxShadow: 'none'
        }
    } as CSSObject,
    containedFire: {
        backgroundColor: `${luminaColorPallete.fire[600]} !important`,
        textTransform: 'none',
        borderRadius: '8px',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: `${luminaColorPallete.fire[500]} !important`,
            boxShadow: 'none'
        },
        '&:focus': {
            backgroundColor: `${luminaColorPallete.fire[800]} !important`,
            boxShadow: 'none'
        },
        '&:disabled': {
            backgroundColor: `${luminaColorPallete.greyscale[400]} !important`,
            color: `${luminaColorPallete.greyscale[200]} !important`,
            boxShadow: 'none'
        }
    } as CSSObject,
    outlined: {
        textTransform: 'none',
        borderColor: `${luminaColorPallete.greyscale[100]} !important`,
        color: `${luminaColorPallete.sky[600]} !important`,
        boxShadow: 'none',
        borderRadius: '8px',
        '&:hover': {
            borderColor: `${luminaColorPallete.sky[600]} !important`,
            backgroundColor: `${luminaColorPallete.sky[50]} !important`,
            color: `${luminaColorPallete.sky[500]} !important`,
            boxShadow: 'none'
        },
        '&:focus': {
            color: `${luminaColorPallete.sky[800]} !important`,
            boxShadow: 'none'
        },
        '&:disabled': {
            backgroundColor: `${luminaColorPallete.greyscale[400]} !important`,
            color: `${luminaColorPallete.greyscale[200]} !important`,
            boxShadow: 'none'
        }
    } as CSSObject,
    text: {
        textTransform: 'none',
        color: `${luminaColorPallete.sky[600]} !important`,
        '&:hover': {
            color: `${luminaColorPallete.sky[400]} !important`
        },
        '&:focus': {
            color: `${luminaColorPallete.sky[800]} !important`
        },
        '&:disabled': {
            color: `${luminaColorPallete.greyscale[300]} !important`,
            boxShadow: 'none'
        }
    } as CSSObject
};
