const config = {
    prod: {
        API_BASE_URL: 'https://work-api.lumina.mba/'
    },
    dev: {
        API_BASE_URL: 'https://work-api-dev.lumina.mba/'
    },
    local: {
        API_BASE_URL: 'http://localhost:8000/'
    }
};

export default {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ...config[process.env.NEXT_PUBLIC_NODE_ENV || 'local']
};
